import SvgGoogle from 'icons/SvgGoogle';
import SvgPhone from 'icons/SvgPhone';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'shared/Button/Button';
import Input, { InputPassword } from 'shared/Input/Input';
import AuthLayout from './layout';
import get from 'lodash.get';
import { FieldValues, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import ErrorText from 'components/ErrorText';

const userSchema = z.object({
  password: z.string().min(5, { message: 'Eng kami 5ta belgi' }),
  phone: z
    .string()
    .length(13, { message: `+998 -- shaklida bo'lishi zerak` })
    .regex(/\+\d+$/, { message: `Xato telefon raqam` }),
});

type TRegisterForm = z.infer<typeof userSchema>;

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(userSchema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = (data: FieldValues | TRegisterForm) => {
    setLoading((loading) => !loading);

    console.log(data);
  };

  const passwordErrorMsg = get(errors, 'password.message', null) as React.ReactNode;
  const phoneErrorMsg = get(errors, 'phone.message', null) as React.ReactNode;

  return (
    <AuthLayout>
      <div className='fcc flex-1'>
        <div className='w-2/5 space-y-7 px-12 py-7  shadow-300 auth__card rounded-lg'>
          <h3 className='font-bold text-3xl'>Akkountga kirish</h3>

          <Button
            type='outlined'
            className='text-lg !border-gray-primary text-gray-primary font-bold'
            leftIcon={<SvgGoogle className='text-white h-6 mr-5' />}
          >
            Google bilan
          </Button>

          <div className='fcb'>
            <div className='flex-1 border border-opacity-70 border-[#C4C4C4]'></div>
            <p className='text-[#949494] text-sm | mx-10'>Yoki</p>
            <div className='flex-1 border border-opacity-70 border-[#C4C4C4]'></div>
          </div>

          <form onSubmit={handleSubmit((data) => onSubmit(data))} className='flex flex-col space-y-7'>
            <Input
              title={`Telefon raqam:`}
              placeholder={`+998 __ - ___ __ __`}
              rightIcon={<SvgPhone className='text-gray-primary h-5' />}
              className={clsx({
                '!border-rose-500': phoneErrorMsg,
              })}
              {...register('phone')}
            />
            <ErrorText>{phoneErrorMsg}</ErrorText>

            <InputPassword
              placeholder={`_._._`}
              title={`Kirish uchun parol:`}
              className={clsx({
                '!border-rose-500': passwordErrorMsg,
              })}
              {...register('password')}
            />
            <ErrorText>{passwordErrorMsg}</ErrorText>

            <Button
              data-testid={`login-btn`}
              loading={loading}
              type='primary'
              htmlType='submit'
              className='font-bold hover:!bg-blue-700'
            >
              Kirish
            </Button>
          </form>

          <p className='text-[#949494] text-sm'>
            Akkauntingiz yo’qmi?
            <Link to={`/auth/register`} className={`text-blue-700 px-3`}>
              Ro’yhattan o’tish
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Login;
