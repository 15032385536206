import If from 'shared/Utilify/If';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import AuthLayout from './layout';
import RegisterStep from './RegisterStep';

function Register() {
  const [register, setRegister] = useQueryParams({
    step: withDefault(NumberParam, 1),
    userName: withDefault(StringParam, ''),
    birth: withDefault(StringParam, ''),
    gender: withDefault(StringParam, 'male'),
    phone: withDefault(StringParam, ''),
    password: withDefault(StringParam, ''),
    verification_code: withDefault(StringParam, ''),
  });

  return (
    <AuthLayout>
      <div data-testid='register-page' className='w-full flex items-center justify-center'>
        <If is={register.step === 1}>
          <RegisterStep.Phone onSuccess={(phoneData) => setRegister({ phone: phoneData.phone, step: 2 })} />
        </If>

        <If is={register.step === 2}>
          <RegisterStep.PhoneValidate
            onSuccess={(phoneVerificationData) =>
              setRegister({ verification_code: phoneVerificationData.verification_code, step: 3 })
            }
          />
        </If>

        <If is={register.step === 3}>
          <RegisterStep.Password onSuccess={(password) => setRegister({ password, step: 4 })} />
        </If>

        <If is={register.step === 4}>
          <RegisterStep.AboutMe onSubmit={() => console.log('navigate with confetti')} />
        </If>
      </div>
    </AuthLayout>
  );
}

export default Register;
