import SvgGoogle from 'icons/SvgGoogle';
import SvgArrow from 'icons/SvgArrow';
import SvgPhone from 'icons/SvgPhone';
import { Link } from 'react-router-dom';
import Button from 'shared/Button/Button';
import Input, { InputPassword } from 'shared/Input/Input';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import get from 'lodash.get';
import ErrorText from 'components/ErrorText';
import SvgCheck from 'icons/SvgCheck';
import clsx from 'clsx';
import { SvgEye, SvgEyeClosed } from 'icons/SvgEye';
import SvgPerson from 'icons/SvgPerson';
import { SvgBoy, SvgGirl } from 'icons/SvgBoy';
import If from 'shared/Utilify/If';
import SvgCheckSolid from 'icons/SvgCheckSolid';

/** ====== PHONE NUMBER INPUT ======= */
const phoneSchema = z.object({
  phone: z
    .string()
    .length(13, { message: `+998 -- shaklida bo'lishi zerak` })
    .regex(/\+\d+$/, { message: `Xato telefon raqam` }),
});

type TypeRegisterPhone = z.infer<typeof phoneSchema>;

type TProps = {
  onSuccess: (data: TypeRegisterPhone) => unknown;
};

function Phone({ onSuccess }: TProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TypeRegisterPhone>({
    resolver: zodResolver(phoneSchema),
  });

  const phoneErrorMsg = get(errors, 'phone.message', null) as React.ReactNode;

  const onSubmit = (data: TypeRegisterPhone) => {
    onSuccess(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='w-2/5 space-y-7 px-12 py-7  shadow-300 auth__card rounded-lg'>
        <h3 className='font-bold text-3xl'>Ro’yhattan o’tish</h3>
        <p className='text-[#949494] text-sm'>Ro’yhattan o’tish orqali siz o'zlashtirgan darslarni saqlab qolasiz</p>

        <div className='flex flex-col space-y-7'>
          <>
            <Input
              htmlType='tel'
              placeholder='+998 __ - ___ __ __'
              {...register('phone')}
              rightIcon={<SvgPhone className='text-gray-primary h-5' />}
              className={clsx({
                '!border-rose-500': phoneErrorMsg,
              })}
            />
            <ErrorText>{phoneErrorMsg}</ErrorText>
          </>

          <Button htmlType='submit' className='font-bold' rightIcon={<SvgArrow className='text-white h-3.5 ml-5' />}>
            Davom
          </Button>
        </div>

        <p className='text-[#949494] text-sm'>
          Platformada royhatta birmisiz?
          <Link to={`/auth/login`} className={`text-blue-700 px-3`}>
            Kirish
          </Link>
        </p>

        <div className='fcb'>
          <div className='flex-1 border border-opacity-70 border-[#C4C4C4]'></div>
          <p className='text-[#949494] text-sm | mx-10'>Yoki</p>
          <div className='flex-1 border border-opacity-70 border-[#C4C4C4]'></div>
        </div>

        <button className='py-2 text-lg border border-gray-primary text-gray-primary font-bold | click:scale w-full fcc'>
          <SvgGoogle className='text-white h-6 mr-5' />
          Google bilan
        </button>
      </form>
    </>
  );
}

/** ====== PHONE NUMBER VALIDATION ======= */
const phoneValidationSchema = z.object({
  verification_code: z
    .string()
    .length(6, { message: `6 talik son` })
    .regex(/[0-9]+/i, { message: `Kod faqat raqamlardan iborat` }),
});

type TypePhoneValidate = z.infer<typeof phoneValidationSchema>;

type TPropsPhoneValidate = {
  onSuccess: (data: TypePhoneValidate) => unknown;
};

function PhoneValidate({ onSuccess }: TPropsPhoneValidate) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TypePhoneValidate>({
    resolver: zodResolver(phoneValidationSchema),
  });

  const verification_codeErrorMsg = get(errors, 'verification_code.message', null) as React.ReactNode;

  const onSubmit = (data: TypePhoneValidate) => {
    onSuccess(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='w-2/5 space-y-7 px-12 py-7  shadow-300 auth__card rounded-lg'>
        <h3 className='font-bold text-3xl'>Tadiqlash</h3>
        <p className='text-[#949494] text-sm'>Jo’natilgan 6 raqamli kodni kiriting:</p>

        <div className='flex flex-col space-y-7'>
          <>
            <Input
              {...register('verification_code')}
              placeholder='___ - ___'
              rightIcon={<SvgCheck className='!text-gray-primary !h-5' />}
              className={clsx({
                '!border-rose-500': verification_codeErrorMsg,
              })}
            />
            <ErrorText>{verification_codeErrorMsg}</ErrorText>
          </>

          <Button className='font-bold' rightIcon={<SvgArrow className='text-white h-3.5 ml-5' />}>
            Davom
          </Button>
        </div>
      </form>
    </>
  );
}

/** ====== PASSWORD INPUT ======= */
const passwordSchema = z
  .object({
    password: z
      .string()
      .min(5, { message: `Parol kamida 5-ta bo'lishi kerak` })
      .max(20, { message: `Parol ko'pi bilan 20-ta bo'lishi kerak` }),
    confirm_password: z
      .string()
      .min(5, { message: `Parol kamida 5-ta bo'lishi kerak` })
      .max(20, { message: `Parol ko'pi bilan 20-ta bo'lishi kerak` }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Tasdiqlash paroli xato!',
    path: ['match'],
  });
type TypePasswordValidate = z.infer<typeof passwordSchema>;

type TPropsPassword = {
  onSuccess: (data: TypePasswordValidate['password']) => unknown;
};

function Password({ onSuccess }: TPropsPassword) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TypePasswordValidate>({
    resolver: zodResolver(passwordSchema),
  });

  const passwordErrMsg = get(errors, 'password.message', null) as React.ReactNode;
  const password_verifyErrMsg = get(errors, 'confirm_password.message', null) as React.ReactNode;
  const passwordsNotMatch = get(errors, 'match.message', null) as React.ReactNode;

  const onSubmit = (data: TypePasswordValidate) => {
    onSuccess(data.password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-2/5 space-y-7 px-12 py-7  shadow-300 auth__card rounded-lg'>
      <h3 className='font-bold text-3xl'>Ximoyalash</h3>

      <div className='flex flex-col space-y-7'>
        <InputPassword
          title={`Akkauntingiz uchun parol`}
          placeholder='Parol'
          rightIcon={<SvgEye className='text-gray-primary h-3.5' />}
          className={clsx({
            '!border-rose-500': passwordErrMsg,
          })}
          {...register('password')}
        />
        <ErrorText>{passwordErrMsg}</ErrorText>

        <InputPassword
          title={`Parolni tastiqlang:`}
          placeholder='Qayta tering:'
          rightIcon={<SvgEyeClosed className='text-gray-primary h-3' />}
          className={clsx({
            '!border-rose-500': password_verifyErrMsg || passwordsNotMatch,
          })}
          {...register('confirm_password')}
        />
        <ErrorText>{password_verifyErrMsg}</ErrorText>
        <ErrorText>{passwordsNotMatch}</ErrorText>

        <Button className='font-bold' rightIcon={<SvgArrow className='text-white h-3.5 ml-5' />}>
          Davom
        </Button>
      </div>
    </form>
  );
}

/** ====== USER DATA FORM ======= */
const userDataSchema = z.object({
  name: z
    .string()
    .min(2, { message: `Ism kamida 2-ta bo'lishi kerak` })
    .max(30, { message: `Ism ko'pi bilan 30-ta bo'lishi kerak` }),
  birth: z.string().optional(),
  gender: z.enum(['male', 'female']),
});

type TUserData = z.infer<typeof userDataSchema>;

type TPropsUserData = {
  onSuccess: (data: TUserData) => unknown;
};

function AboutMe({ onSubmit }: { onSubmit: (data: TPropsUserData) => unknown }) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TUserData>({
    resolver: zodResolver(userDataSchema),
    defaultValues: {
      gender: 'male',
    },
  });
  const nameErr = get(errors, 'name.message', null) as React.ReactNode;

  const onFinish = (data: TUserData) => {
    console.log(data);
  };

  const gender = watch('gender');

  return (
    <form onSubmit={handleSubmit(onFinish)} className='w-2/5 space-y-7 px-12 py-7  shadow-300 auth__card rounded-lg'>
      <h3 className='font-bold text-3xl'>Ma’lumotlar</h3>

      <div className='flex flex-col space-y-7'>
        <Input
          title={`Sizni nima deb chaqiraylik?`}
          placeholder='Ismingiz'
          rightIcon={<SvgPerson className='text-gray-primary h-5' />}
          {...register('name')}
          className={clsx({
            '!border-rose-500': nameErr,
          })}
        />
        <ErrorText>{nameErr}</ErrorText>

        <Input title={`Tugi’lgan sanangiz`} htmlType={`date`} {...register('birth')} />

        <div className='space-y-3'>
          <p className='text-[#949494] text-sm'>Jinsingiz</p>

          <div className='flex | space-x-3'>
            <button
              onClick={() => setValue('gender', 'male')}
              className={clsx('relative fcc p-2 | text-xl border text-gray-primary font-bold | click:scale w-full', {
                'border-gray-primary': gender === 'male',
                'opacity-75': gender === 'female',
              })}
            >
              <SvgBoy className='text-white h-[80px] mr-5' />
              Erkak
              <If is={gender === 'male'}>
                <div className='absolute top-0 right-0 h-7 w-7 bg-gray-primary fcc'>
                  <SvgCheckSolid className='h-3 text-white' />
                </div>
              </If>
            </button>

            <button
              onClick={() => setValue('gender', 'female')}
              className={clsx('relative fcc p-2 | text-xl border text-gray-primary font-bold | click:scale w-full', {
                'border-gray-primary': gender === 'female',
                'opacity-75': gender === 'male',
              })}
            >
              <SvgGirl className='text-white h-[70px] mr-5' />
              Ayol
              <If is={gender === 'female'}>
                <div className='absolute top-0 right-0 h-7 w-7 bg-gray-primary fcc'>
                  <SvgCheckSolid className='h-3 text-white' />
                </div>
              </If>
            </button>
          </div>
        </div>

        <Button className='font-bold'>Yakunlash</Button>
      </div>
    </form>
  );
}
const RegisterStep = () => {};

RegisterStep.Phone = Phone;
RegisterStep.PhoneValidate = PhoneValidate;
RegisterStep.Password = Password;
RegisterStep.AboutMe = AboutMe;

export default RegisterStep;
