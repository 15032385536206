function SvgCheck(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 18' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M9.67.108a8.672 8.672 0 100 17.344 8.672 8.672 0 000-17.344zM1.95 8.78a7.722 7.722 0 1115.444 0 7.722 7.722 0 01-15.444 0zm11.13-2.712a.5.5 0 00-.817-.576l-3.877 5.51-1.69-1.722a.5.5 0 00-.714.7l2.11 2.15a.5.5 0 00.766-.062l4.223-6z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default SvgCheck;
