import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { SvgEye, SvgEyeClosed } from 'icons/SvgEye';
import React, { forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react';

type TSizeOptions = 'sm' | 'md' | 'lg';

type IProps = InputHTMLAttributes<HTMLInputElement> & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  title?: React.ReactNode;
  size?: TSizeOptions;
  rounded?: TSizeOptions | 'none';
  placeholder?: string;
  value?: string;
  htmlType?: HTMLInputTypeAttribute;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  name: string;
};

const input = cva('w-full', {
  variants: {
    rounded: {
      none: 'rounded-none',
      sm: 'rounded-[2px]',
      md: 'rounded-md',
      lg: 'rounded-lg',
    } as Record<TSizeOptions | 'none', string>,
    size: {
      sm: 'py-2 px-2 text-sm',
      md: 'py-3 px-2 text-base',
      lg: 'py-3 px-3 text-lg',
    } as Record<TSizeOptions, string>,
  },
});

const Input = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    size = 'md',
    name,
    placeholder,
    title,
    rounded = 'lg',
    value,
    htmlType = 'text',
    rightIcon,
    leftIcon,
    ...rest
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(e);
  };

  return (
    <label
      htmlFor={name}
      className={clsx('group', {
        'space-y-3': title,
      })}
    >
      {title ? <p className='text-[#949494] text-sm'>{title}</p> : null}

      <div
        className={clsx(
          'relative  | border border-opacity-30 focus-within:border-opacity-100 border-gray-primary duration-200 overflow-hidden',
          rest.className,
          input({ rounded })
        )}
      >
        <div className='relative flex items-center justify-between'>
          {leftIcon ? <div className='ml-3'>{leftIcon}</div> : null}

          <input
            {...rest}
            value={value}
            onChange={handleChange}
            ref={ref}
            type={htmlType || 'text'}
            name={name}
            id={name}
            placeholder={placeholder}
            className={input({ size })}
          />

          {rightIcon ? <div className='mr-3'>{rightIcon}</div> : null}
        </div>
      </div>
    </label>
  );
});

export const InputPassword = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const { ...rest } = props;
  const [password, setPassword] = useState({ text: '', visible: false });

  const togglePassword = () => {
    setPassword((password) => {
      return { ...password, visible: !password.visible };
    });
  };

  return (
    <Input
      {...rest}
      htmlType={password.visible ? 'text' : 'password'}
      rightIcon={
        password.visible ? (
          <SvgEye className='text-gray-primary cursor-pointer h-3.5' onClick={togglePassword} />
        ) : (
          <SvgEyeClosed className='text-gray-primary cursor-pointer h-2.5' onClick={togglePassword} />
        )
      }
      ref={ref}
    />
  );
});

export default Input;
