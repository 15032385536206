function SvgEye(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 12' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M10 10.833c-3.697 0-6.804-1.91-8.739-4.833C3.196 3.077 6.303 1.167 10 1.167c3.697 0 6.804 1.91 8.739 4.833-1.935 2.922-5.042 4.833-8.74 4.833zM10 .167c-4.192 0-7.677 2.24-9.757 5.568a.5.5 0 000 .53c2.08 3.328 5.565 5.568 9.757 5.568 4.192 0 7.677-2.24 9.757-5.568a.5.5 0 000-.53C17.677 2.406 14.192.167 10 .167zm0 8.5a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.333z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

function SvgEyeClosed(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 10' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M19.598.243a.5.5 0 01.16.689 12.496 12.496 0 01-2.171 2.614L19.31 5.27a.5.5 0 01-.707.707L16.817 4.19a11.137 11.137 0 01-3.775 1.896l.64 2.394a.5.5 0 11-.965.259l-.65-2.426C11.4 6.436 10.71 6.5 10 6.5c-.71 0-1.4-.064-2.067-.187l-.65 2.426a.5.5 0 11-.966-.259l.641-2.394A11.137 11.137 0 013.183 4.19L1.397 5.977a.5.5 0 01-.708-.707l1.724-1.724A12.498 12.498 0 01.243.932a.5.5 0 11.848-.53A11.4 11.4 0 003.48 3.15 10.115 10.115 0 0010 5.5c3.808 0 6.99-2.027 8.91-5.098a.5.5 0 01.688-.16z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export { SvgEye, SvgEyeClosed };
