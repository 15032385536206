function SvgGoogle(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 36 36' {...props}>
      <path
        fill='#05192D'
        d='M34.84 14.146h-1.416v-.073H17.592v7.037h9.941c-1.45 4.096-5.348 7.036-9.942 7.036-5.829 0-10.554-4.726-10.554-10.555S11.762 7.037 17.592 7.037c2.69 0 5.138 1.015 7.002 2.673l4.976-4.976C26.428 1.806 22.225 0 17.59 0 7.877 0 0 7.877 0 17.591c0 9.715 7.877 17.592 17.591 17.592 9.715 0 17.592-7.877 17.592-17.591 0-1.18-.121-2.331-.342-3.446z'
      ></path>
      <path
        fill='#05192D'
        d='M2.028 9.404l5.78 4.238c1.564-3.872 5.351-6.605 9.784-6.605 2.69 0 5.138 1.015 7.002 2.673l4.976-4.976C26.428 1.806 22.225 0 17.59 0 10.835 0 4.976 3.815 2.028 9.404zM17.591 35.183c4.544 0 8.673-1.739 11.795-4.567L23.94 26.01a10.476 10.476 0 01-6.35 2.137c-4.575 0-8.46-2.917-9.924-6.989l-5.736 4.42c2.911 5.697 8.823 9.606 15.66 9.606z'
      ></path>
      <path
        fill='#05192D'
        d='M34.84 14.146l-.013-.073H17.592v7.037h9.941a10.591 10.591 0 01-3.594 4.9l.002-.002 5.445 4.607c-.386.35 5.797-4.228 5.797-13.023 0-1.18-.121-2.331-.342-3.446z'
      ></path>
    </svg>
  );
}

export default SvgGoogle;
