function SvgBoy(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 72 81' {...props}>
      <rect
        width='14.033'
        height='26.358'
        x='29.006'
        y='38.057'
        fill='#fff'
        stroke='#323232'
        strokeLinecap='round'
        strokeWidth='0.823'
        rx='2.834'
      ></rect>
      <path fill='#7E7E7E' fillOpacity='0.45' d='M29.395 55.056v-8.425h14.113c-1.063 4.32-7.4 8.658-14.113 8.425z'></path>
      <path
        fill='#fff'
        stroke='#323232'
        strokeWidth='0.823'
        d='M27.566 36.654c0 2.405-2.17 4.423-4.937 4.423-2.767 0-4.937-2.018-4.937-4.423 0-2.404 2.17-4.423 4.937-4.423 2.767 0 4.937 2.019 4.937 4.423zM44.64 36.654c0 2.405 2.17 4.423 4.937 4.423 2.767 0 4.937-2.018 4.937-4.423 0-2.404-2.17-4.423-4.937-4.423-2.767 0-4.937 2.019-4.937 4.423z'
      ></path>
      <g clipPath='url(#clip0_523_2377)'>
        <path
          fill='#fff'
          stroke='#323232'
          strokeLinecap='round'
          strokeWidth='0.823'
          d='M49.783 35.231c0 4.619-1.226 8.14-3.5 10.507-2.272 2.364-5.648 3.634-10.07 3.634-4.424 0-7.857-1.27-10.184-3.637-2.328-2.368-3.606-5.89-3.606-10.504 0-4.613 1.277-7.922 3.593-10.082 2.322-2.165 5.755-3.24 10.196-3.24 4.442 0 7.818 1.075 10.084 3.237 2.262 2.159 3.487 5.467 3.487 10.085z'
        ></path>
        <path
          stroke='#323232'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.823'
          d='M36.206 38.47c-.405 1.4-.617 2.133-.617 3.59.543.26 1.062.325 1.954.318'
        ></path>
        <path
          fill='#323232'
          stroke='#323232'
          strokeLinecap='round'
          strokeWidth='0.206'
          d='M28.958 41.19c.424 0 .8-.23 1.067-.585.266-.356.428-.842.428-1.375 0-.532-.162-1.019-.428-1.374-.267-.355-.643-.585-1.067-.585-.424 0-.8.23-1.067.585-.266.355-.428.842-.428 1.374 0 .533.162 1.02.428 1.375.267.355.643.584 1.067.584z'
        ></path>
        <circle cx='29.544' cy='38.775' r='0.514' fill='#fff' stroke='#323232' strokeWidth='0.206'></circle>
        <path
          fill='#323232'
          stroke='#323232'
          strokeLinecap='round'
          strokeWidth='0.206'
          d='M43.77 41.19c.424 0 .8-.23 1.067-.585.266-.356.428-.842.428-1.375 0-.532-.162-1.019-.428-1.374-.267-.355-.643-.585-1.067-.585-.424 0-.8.23-1.067.585-.266.355-.428.842-.428 1.374 0 .533.162 1.02.428 1.375.267.355.643.584 1.067.584z'
        ></path>
        <circle cx='44.356' cy='38.775' r='0.411' fill='#fff'></circle>
        <path
          stroke='#323232'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.823'
          d='M34.766 44.78c.617.754 2.222 1.81 3.703 0'
        ></path>
        <g filter='url(#filter0_f_523_2377)'>
          <ellipse cx='28.081' cy='43.509' fill='#FC9D80' rx='1.954' ry='1.131'></ellipse>
        </g>
        <g filter='url(#filter1_f_523_2377)'>
          <ellipse cx='44.332' cy='43.509' fill='#FC9D80' rx='1.954' ry='1.131'></ellipse>
        </g>
      </g>
      <path
        fill='#323232'
        d='M19.851 27.36c-.69 6.173 2.572 10.389 3.189 10.903 0 0 .664-5.46 3.248-9.771 7.91 2.848 11.168 2.941 19.072 0 1.337 2.263 3.086 5.554 3.858 9.771 1.285-.514 3.321-9.988 2.622-14.4-.699-4.412-4.085-4.09-4.085-4.09s2.44-2.19 1.463-4.38c-.975-2.19-5.61 0-5.61 0s2.195-3.163-1.464-4.136c-3.66-.973-8.05 3.163-8.05 3.163s1.707-3.163-1.464-3.163c-3.171 0-6.342 6.326-6.342 6.326s-.756-2.482-2.22-1.023c-1.463 1.46-.708 6.619-.708 6.619s-2.818-1.991-3.509 4.181z'
      ></path>
      <path
        fill='#fff'
        stroke='#323232'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.823'
        d='M29.043 50.406c.094-.043.23-.009.3.11 3.32 5.755 11.362 5.305 13.87.053.068-.143.222-.185.32-.14 5.547 2.513 9.188 5.186 11.675 8.677 2.49 3.494 3.853 7.85 4.76 13.79a.205.205 0 01-.2.235H12.44a.205.205 0 01-.2-.236c.98-6.236 2.591-10.644 5.231-14.091 2.64-3.448 6.34-5.976 11.572-8.398z'
      ></path>
      <path
        fill='#323232'
        stroke='#323232'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.823'
        d='M12.24 72.895c.97-6.172 2.558-10.553 5.15-13.984 2.591-3.432 6.215-5.953 11.331-8.355a.2.2 0 01.285.183v22.187a.206.206 0 01-.206.205H12.44a.205.205 0 01-.2-.236zM60.171 72.895c-.97-6.172-2.558-10.553-5.15-13.984-2.591-3.432-6.215-5.953-11.331-8.355a.2.2 0 00-.284.183v22.187c0 .113.092.205.205.205h16.36c.123 0 .22-.112.2-.236z'
      ></path>
      <defs>
        <filter
          id='filter0_f_523_2377'
          width='8.846'
          height='7.2'
          x='23.658'
          y='39.909'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_523_2377' stdDeviation='1.234'></feGaussianBlur>
        </filter>
        <filter
          id='filter1_f_523_2377'
          width='8.846'
          height='7.2'
          x='39.909'
          y='39.909'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_523_2377' stdDeviation='1.234'></feGaussianBlur>
        </filter>
        <clipPath id='clip0_523_2377'>
          <path fill='#fff' d='M0 0H28.183V39.086H0z' transform='translate(22.012 11.109)'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function SvgGirl(prop: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 62 70' {...prop}>
      <path
        fill='#fff'
        stroke='#323232'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.709'
        d='M25.01 48.011c.082-.037.2-.008.258.095 2.86 4.955 9.785 4.567 11.945.045.059-.123.191-.16.276-.12 4.776 2.163 7.911 4.466 10.053 7.471 2.144 3.01 3.318 6.76 4.099 11.875a.176.176 0 01-.173.203H10.713a.176.176 0 01-.171-.203c.843-5.37 2.231-9.166 4.504-12.135 2.274-2.969 5.46-5.146 9.965-7.23z'
      ></path>
      <path
        stroke='#323232'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.709'
        d='M19.398 51.315l5.016 7.09c.064.09.192.101.27.024l6.453-6.453a.177.177 0 01.255.006l5.933 6.442c.075.08.203.075.27-.011l5.54-7.098'
      ></path>
      <circle cx='31.445' cy='57.692' r='1.683' fill='#323232'></circle>
      <circle cx='31.445' cy='62.474' r='1.683' fill='#323232'></circle>
      <rect
        width='12.084'
        height='22.698'
        x='24.977'
        y='32.771'
        fill='#fff'
        stroke='#323232'
        strokeLinecap='round'
        strokeWidth='0.709'
        rx='2.44'
      ></rect>
      <path fill='#7E7E7E' fillOpacity='0.45' d='M25.313 47.41v-7.256h12.153c-.916 3.72-6.373 7.456-12.153 7.255z'></path>
      <path
        fill='#fff'
        stroke='#323232'
        strokeWidth='0.709'
        d='M23.383 32.151c0 2.07-1.87 3.809-4.252 3.809-2.382 0-4.251-1.738-4.251-3.809 0-2.07 1.869-3.808 4.251-3.808 2.383 0 4.252 1.738 4.252 3.808zM38.794 32.151c0 2.07 1.869 3.809 4.251 3.809 2.383 0 4.252-1.738 4.252-3.809 0-2.07-1.87-3.808-4.252-3.808-2.382 0-4.251 1.738-4.251 3.808z'
      ></path>
      <path
        fill='#fff'
        stroke='#323232'
        strokeLinecap='round'
        strokeWidth='0.709'
        d='M42.868 30.338c0 3.977-1.055 7.01-3.014 9.047-1.956 2.036-4.863 3.13-8.672 3.13s-6.764-1.094-8.768-3.133c-2.005-2.038-3.106-5.071-3.106-9.044 0-3.979 1.104-8.128 3.15-11.272 2.043-3.14 5-5.249 8.724-5.249 3.723 0 6.633 2.107 8.629 5.246 1.999 3.143 3.057 7.294 3.057 11.275z'
      ></path>
      <path
        stroke='#323232'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.709'
        d='M31.177 30.114c-.35 1.207-.532 1.837-.532 3.093.468.223.915.28 1.683.273'
      ></path>
      <path
        fill='#323232'
        stroke='#323232'
        strokeLinecap='round'
        strokeWidth='0.177'
        d='M24.936 32.102c.365 0 .689-.197.918-.503.23-.306.37-.725.37-1.184 0-.458-.14-.877-.37-1.183-.23-.306-.553-.504-.918-.504-.366 0-.69.198-.919.504-.23.306-.369.725-.369 1.183 0 .46.14.878.37 1.184.229.306.552.503.918.503z'
      ></path>
      <circle cx='25.44' cy='30.023' r='0.443' fill='#fff' stroke='#323232' strokeWidth='0.177'></circle>
      <path
        fill='#323232'
        stroke='#323232'
        strokeLinecap='round'
        strokeWidth='0.177'
        d='M37.69 32.102c.366 0 .69-.197.92-.503.229-.306.368-.725.368-1.184 0-.458-.14-.877-.369-1.183-.23-.306-.553-.504-.919-.504-.365 0-.688.198-.918.504-.23.306-.369.725-.369 1.183 0 .46.14.878.369 1.184.23.306.553.503.919.503z'
      ></path>
      <circle cx='38.195' cy='30.023' r='0.354' fill='#fff'></circle>
      <path
        stroke='#323232'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.709'
        d='M29.76 36.226c.531.65 1.913 1.558 3.188 0'
      ></path>
      <g filter='url(#filter0_f_523_2386)'>
        <ellipse cx='24.18' cy='35.34' fill='#FC9D80' rx='1.683' ry='0.974'></ellipse>
      </g>
      <g filter='url(#filter1_f_523_2386)'>
        <ellipse cx='38.174' cy='35.34' fill='#FC9D80' rx='1.683' ry='0.974'></ellipse>
      </g>
      <path
        fill='#323232'
        d='M34.188 18.068c-3.87 7.511-7.061 9.17-15.411 9.92-1.683-11.425 1.003-13.852 13.551-15.765 10.212 3.404 10.473 6.662 10.717 15.765-6.123-1.745-6.67-4.286-8.857-9.92z'
      ></path>
      <path
        fill='#323232'
        fillRule='evenodd'
        d='M31.422 51.549c10.076 0 18.245-10.072 18.245-22.497S41.498 8.149 31.422 8.149c-10.077 0-18.246 8.478-18.246 20.903 0 12.425 8.169 22.497 18.245 22.497zm-.355-9.92c6.555 0 11.869-5.67 11.869-12.666 0-6.995-5.314-12.666-11.869-12.666S19.2 21.968 19.2 28.963 24.512 41.63 31.067 41.63z'
        clipRule='evenodd'
      ></path>
      <g filter='url(#filter2_d_523_2386)'>
        <path
          fill='#323232'
          d='M19.65 41.629c-2.532 0-4.586 2.538-4.586 5.669 0 .508.054 1.002.157 1.472-.831 1.144-2.274 1.805-2.097 4.462.177 2.657 5.757 12.055 10.008 12.665 10.345 1.486 10.093-5.498 18.689-6.908 2.572-.422 7.263-2.64 7.263-5.846 0-1.84-1.094-3.473-2.083-4.662.056-.36.086-.73.086-1.108 0-3.172-2.08-5.744-4.648-5.744H19.65z'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_f_523_2386'
          width='7.617'
          height='6.2'
          x='20.371'
          y='32.24'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_523_2386' stdDeviation='1.063'></feGaussianBlur>
        </filter>
        <filter
          id='filter1_f_523_2386'
          width='7.617'
          height='6.2'
          x='34.365'
          y='32.24'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'></feBlend>
          <feGaussianBlur result='effect1_foregroundBlur_523_2386' stdDeviation='1.063'></feGaussianBlur>
        </filter>
        <filter
          id='filter2_d_523_2386'
          width='40.227'
          height='28.726'
          x='10.983'
          y='39.858'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='0.354'></feOffset>
          <feGaussianBlur stdDeviation='1.063'></feGaussianBlur>
          <feColorMatrix values='0 0 0 0 0.0784314 0 0 0 0 0.137255 0 0 0 0 0.490196 0 0 0 0.16 0'></feColorMatrix>
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_523_2386'></feBlend>
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_523_2386' result='shape'></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export { SvgBoy, SvgGirl };
