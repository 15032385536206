interface IProps {
  children: React.ReactNode;
  is: boolean;
  byCss?: boolean;
}

function If({ is, children, byCss = false }: IProps): JSX.Element {
  if (byCss) {
    return <div style={{ display: is ? 'block' : 'none' }}>{children}</div>;
  }

  return <>{is ? children : null}</>;
}

export default If;
