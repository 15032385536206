function SvgPhone(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 18' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M2.633 3.217c.002 0-.04.043-.093.095-.201.196-.619.603-.623 1.456-.007 1.192.778 3.407 4.553 7.182 3.759 3.756 5.97 4.55 7.165 4.55h.017c.853-.005 1.26-.423 1.456-.624.06-.061.108-.106.143-.135.83-.835 1.255-1.456 1.25-1.855-.006-.406-.511-.887-1.21-1.552a39.316 39.316 0 01-.72-.697c-.66-.66-.988-.547-1.708-.293-.996.349-2.362.824-4.486-1.3-2.127-2.125-1.65-3.49-1.302-4.486.251-.72.366-1.048-.295-1.71-.26-.258-.492-.503-.706-.728-.661-.695-1.138-1.198-1.542-1.204h-.007c-.399 0-1.019.427-1.896 1.304l.004-.003zM13.635 17.75c-2.076 0-4.784-1.654-8.048-4.916C2.31 9.558.655 6.842.667 4.76c.008-1.375.728-2.079 1-2.343.013-.018.062-.065.078-.082C2.94 1.14 3.746.657 4.548.666c.93.013 1.593.71 2.432 1.592.207.218.432.456.683.706 1.217 1.216.87 2.209.592 3.006-.304.869-.566 1.619 1.005 3.19 1.574 1.572 2.324 1.31 3.19 1.004.797-.279 1.787-.627 3.006.59.246.246.48.469.696.675.887.842 1.587 1.508 1.599 2.441.01.796-.474 1.607-1.667 2.801l-.528-.358.446.437c-.264.27-.967.992-2.343 1h-.024z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default SvgPhone;
