import { Routes, Route } from 'react-router-dom';
import Course from 'pages/course/[slug]';
import Lesson from 'pages/lesson/[slug]';
import Home from 'pages/index';
import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import SettingsWrapper from 'SettingsWrapper';

function App() {
  return (
    <>
      <SettingsWrapper>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/course' element={<Course />} />
          <Route path='/lesson/:lesson' element={<Lesson />} />
          <Route path='/auth/login' element={<Login />} />
          <Route path='/auth/register' element={<Register />} />
        </Routes>
      </SettingsWrapper>
    </>
  );
}

export default App;
