import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import useScrollToTop from 'hooks/useScrollToTop';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import React from 'react';
import './tailwind.css';

function SettingsWrapper({ children }: { children: React.ReactNode }) {
  useScrollToTop();
  let location = useLocation();

  return (
    <>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Toaster />

        <TransitionGroup className={`h-full w-full`}>
          <CSSTransition key={location.pathname} classNames='fade' timeout={250}>
            {children}
          </CSSTransition>
        </TransitionGroup>
      </QueryParamProvider>
    </>
  );
}

export default SettingsWrapper;
