import Logo from 'components/Logo';

type TProps = {
  children?: React.ReactNode;
};
function AuthLayout({ children }: TProps) {
  return (
    <section className='container mx-auto px-5 | h-full w-full | flex flex-col justify-between'>
      <div className='go__home | py-5'>
        <Logo className='h-7' />
      </div>

      <div className='fcc flex-1'>{children}</div>
    </section>
  );
}

export default AuthLayout;
