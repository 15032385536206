import React, { useLayoutEffect, useRef } from 'react';

function ErrorText({ children, ...rest }: { children: React.ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const refP = useRef<HTMLParagraphElement>(null);

  useLayoutEffect(() => {
    if (ref.current && refP.current && children) {
      ref.current.style.height = `${refP.current.offsetHeight}px`;
    }
  });

  return (
    <div ref={ref} className='overflow-hidden !my-0 duration-[125ms] h-0'>
      <p ref={refP} className='text-sm text-rose-500 pt-2' {...rest}>
        {children}
      </p>
    </div>
  );
}

export default ErrorText;
