import { useCallback, useRef } from 'react';

type IProps = {
  to?: 'top';
};

function useScroll<T extends HTMLElement>({ to }: IProps = {}) {
  const scrollRef = useRef<T>(null);

  const scrollToRef = useCallback(() => {
    if (to) {
      window.scrollTo({
        top: 0,
      });

      return scrollRef.current;
    }

    scrollRef.current?.scrollIntoView();

    return scrollRef.current;
  }, []);

  return { ref: scrollRef, scroll: scrollToRef };
}

export default useScroll;
