import { App } from 'components/Layout/App';
import CourseCard from 'app/course/CourseCard';
import Header from 'components/Header';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Footer from 'components/Footer';
import SvgRocket from 'icons/SvgRocket';
import Button from 'shared/Button/Button';
import useScroll from 'hooks/useScroll';

function Home() {
  const navigate = useNavigate();
  const { ref, scroll } = useScroll<HTMLDivElement>();

  return (
    <App className='scroll-white'>
      <App.Header className='bg-glass-100'>
        <Header />
      </App.Header>

      <App.Container>
        <section className='h-full flex-col fc pt-24 pb-20 md:pt-20 | space-y-5 md:space-y-10 relative z-20'>
          <div
            className={`circle-animate md:w-72 w-36 h-32 md:h-64 absolute left-[-10rem] top-0 blur-[50px] opacity-50 md:opacity-30 duration-200 z-10`}
          ></div>

          <h1 className='uppercase text-4xl md:text-5xl font-bold | z-20 relative'>
            <span className='gradient-blue'>6 oyda</span>

            <span className='text-gray-700 absolute inset-0 | overlay-1'>6 oyda</span>
          </h1>

          <h1 className='uppercase text-4xl md:text-5xl font-bold | z-20 relative'>
            <span className='gradient-pink'>JUNIOR Frontend</span>
            <span className='text-gray-700 absolute inset-0 | overlay-2'>JUNIOR Frontend</span>
          </h1>

          <h1 className='uppercase text-4xl md:text-5xl font-bold | z-20 relative text-center'>
            <span className='gradient-yellow'>dasturchi bo'ling</span>
            <span className='text-gray-700 absolute inset-0 | overlay-3'>dasturchi bo'ling</span>
          </h1>
          <p className='py-5 md:py-0 text-sm md:text-base mt-6 max-w-2xl mx-auto text-center text-gray-700 leading-relaxed md:mt-8 lg:mt-10'>
            Codify ta'lim platofrmasida Junior Frontendchi bo'lish uchun kerakli kurslarni mutlaqo <b>BEPUL</b>{' '}
            o'rganishingiz mumkun!
          </p>
          <div className='absolute top-1/5 md:top-1/4 right-0 h-14 md:h-20 pointer-events-none | z-10'>
            <img src='https://i.ibb.co/Kj1DsJt/mouse-keyboard.png' alt='mouse' className='h-full object-contain' />
          </div>
          <div className='w-full md:w-1/2 px-5 md:px-0 flex-col md:flex-row flex items-center justify-center md:space-x-10 space-y-5 md:space-y-0 | md:pt-8'>
            <Button
              onClick={() => scroll()}
              rightIcon={<SvgRocket className='h-4 ml-3' />}
              className='uppercase font-semibold'
            >
              Boshlash
            </Button>

            <Button
              onClick={() => toast.error('Platforma beta versiyada!')}
              type='outlined'
              rightIcon={<SvgRocket className='h-4 ml-3' />}
            >
              Ro’yxattan o’tish
            </Button>
          </div>
        </section>
      </App.Container>

      <div ref={ref}></div>

      <section className='bg-[#c6fe6c24]'>
        <div className='px-3 md:px-0 mt-10 md:mt-30 py-10'>
          <div className='available:courses'>
            <h1 className='md:text-3xl text-2xl font-bold md:px-28 | text-gray-primary'>Kurslar:</h1>
            <div className='courses | grid xl:grid-cols-2 grid-cols-1 md:gap-x-20 md:gap-y-20 gap-y-14 | xl:px-28 pt-5'>
              <div onClick={() => navigate('/course')} className='relative'>
                <CourseCard image={'https://i.ibb.co/Tr8Nntq/htmll.png'} progress={0} language={`HTML`} level={1} />
              </div>
            </div>
          </div>

          <div className='coming:courses | pt-12 md:pt-36'>
            <div className='available:courses'>
              <h1 className='md:text-3xl text-2xl font-bold md:px-28 | text-gray-primary'>Tez orada:</h1>

              <div className='courses | grid xl:grid-cols-2 grid-cols-1 md:gap-x-20 md:gap-y-20 gap-y-14 | xl:px-28 pt-5'>
                <div
                  onClick={() =>
                    toast('Tez orada!', {
                      icon: '⌛',
                    })
                  }
                >
                  <CourseCard
                    image={'https://i.ibb.co/9VrT1qB/Group.png'}
                    progress={0}
                    language={`Vue JS`}
                    level={3}
                    wait={true}
                  />
                </div>
                <div
                  onClick={() =>
                    toast('Tez orada!', {
                      icon: '⌛',
                    })
                  }
                  className='relative'
                >
                  <CourseCard image={'https://i.ibb.co/pPCpKXK/css.png'} progress={10} language={`CSS`} level={2} />
                </div>

                <div
                  onClick={() =>
                    toast('Tez orada!', {
                      icon: '⌛',
                    })
                  }
                  className='relative'
                >
                  <CourseCard
                    image={'https://i.ibb.co/MDwS9Db/Group-230-2.png'}
                    progress={33}
                    language={`Javascript`}
                    level={2}
                  />
                </div>

                <div
                  onClick={() =>
                    toast('Tez orada!', {
                      icon: '⌛',
                    })
                  }
                  className='relative'
                >
                  <CourseCard
                    image={'https://i.ibb.co/JnR1qXW/Group-230-1.png'}
                    progress={45}
                    language={`React JS`}
                    level={3}
                    new={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='why:we w-full overflow-hidden | pt-28 px-3'>
        <h1 className='title | text-3xl font-extrabold | text-gray-primary | pt-5 pb-10 | text-center uppercase'>
          <span className='gradient-blue'>Codify</span> qanday ishlaydi?
        </h1>
        <div className='space-y-16 md:space-y-0'>
          <div className='start | flex-col md:flex-row flex items-center justify-between relative'>
            <div className='info | md:w-1/2'>
              <h1 className='title | text-2xl md:text-3xl font-extrabold | text-gray-primary | pb-5'>Teoriya.</h1>
              <p className='text-[#787878] text-sm w-3/4'>
                Sizning kelajagingiz uchun professionallar tomonidan tayyorlangan eng yaxshi maqolalarni o'qing
              </p>

              <ul className='pt-5'>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Yoqimli interfeys
                </li>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Qabul qilish oson
                </li>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Interaktiv rejim
                </li>
              </ul>
            </div>

            <div className='hidden md:flex h-96 w-[1.1px] bg-gradient-to-b from-blue-300/50 to-blue-700 | relative flex-col items-center justify-center'>
              <div className='box | bg-gradient-blue w-8 h-8 flex items-center justify-center text-white rounded-full | '>
                1
              </div>
            </div>

            <div className='image | h-48 md:h-96 w-1/2 overflow-hidden md:relative absolute scale-150 md:scale-100 -right-1/4 top-10 md:top-0 md:right-0'>
              <img
                src='https://i.ibb.co/87D9wJ1/article.png'
                alt='codify.uz - Article'
                className='h-full object-contain mx-auto'
              />
            </div>
          </div>

          <div className='start | flex-col md:flex-row flex items-center justify-between relative'>
            <div className='info | md:w-1/2'>
              <h1 className='title | text-2xl md:text-3xl font-extrabold | text-gray-primary | pb-5'>Amaliyot.</h1>
              <p className='text-[#787878] text-sm w-3/4'>
                Sizning kelajagingiz uchun professionallar tomonidan tayyorlangan eng yaxshi maqolalarni o'qing
              </p>

              <ul className='pt-5'>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Yoqimli interfeys
                </li>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Qabul qilish oson
                </li>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Interaktiv rejim
                </li>
              </ul>
            </div>

            <div className='hidden md:flex h-96 w-[1.1px] bg-blue-700 | relative flex-col items-center justify-center'>
              <div className='box | bg-gradient-blue w-8 h-8 flex items-center justify-center text-white rounded-full | '>
                2
              </div>
            </div>

            <div className='image | h-48 md:h-96 w-1/2 overflow-hidden md:relative absolute scale-150 md:scale-100 -right-1/4 top-10 md:top-0 md:right-0'>
              <img
                src='https://i.ibb.co/q7H8cm4/editor.png'
                alt='codify.uz - Article'
                className='h-full object-contain mx-auto'
              />
            </div>
          </div>

          <div className='start | flex-col md:flex-row flex items-center justify-between relative'>
            <div className='info | md:w-1/2'>
              <h1 className='title | text-2xl md:text-3xl font-extrabold | text-gray-primary | pb-5'>Testlar.</h1>
              <p className='text-[#787878] text-sm w-3/4'>
                Sizning kelajagingiz uchun professionallar tomonidan tayyorlangan eng yaxshi maqolalarni o'qing
              </p>

              <ul className='pt-5'>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Yoqimli interfeys
                </li>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Qabul qilish oson
                </li>
                <li className='| text-gray-primary | fc | mb-2'>
                  <span className='text-lg mr-3'>✔</span> Interaktiv rejim
                </li>
              </ul>
            </div>

            <div className='hidden md:flex h-96 w-[1.1px] bg-gradient-to-b from-blue-700 to-blue-300/50 | relative flex-col items-center justify-center'>
              <div className='box | bg-gradient-blue w-8 h-8 flex items-center justify-center text-white rounded-full | '>
                3
              </div>
            </div>

            <div className='image | h-48 md:h-96 w-1/2 overflow-hidden md:relative absolute scale-150 md:scale-100 -right-1/4 top-10 md:top-0 md:right-0'>
              <img
                src='https://i.ibb.co/HTJ3VTh/exersice.png'
                alt='codify.uz - Article'
                className='h-full object-contain mx-auto'
              />
            </div>
          </div>
        </div>
      </div>

      <App.Footer className='shadow-300 mt-10 py-7 px-3'>
        <Footer />
      </App.Footer>
    </App>
  );
}

export default Home;
