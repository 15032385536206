function SvgPerson(props: any) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20' {...props}>
      <path
        fill='#05192D'
        fillRule='evenodd'
        d='M10 1.325a4.675 4.675 0 100 9.35 4.675 4.675 0 000-9.35zM6.275 6a3.725 3.725 0 117.45 0 3.725 3.725 0 01-7.45 0zM3.808 18.134c0-2.32.612-3.913 1.642-4.929 1.032-1.017 2.56-1.53 4.55-1.53 1.99 0 3.518.513 4.55 1.53 1.03 1.016 1.642 2.608 1.642 4.929a.475.475 0 10.95 0c0-2.48-.656-4.354-1.925-5.606-1.268-1.25-3.074-1.803-5.217-1.803s-3.949.554-5.217 1.803c-1.27 1.252-1.925 3.125-1.925 5.606a.475.475 0 00.95 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default SvgPerson;
