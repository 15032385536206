import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { TSizeOptions, TTypeOptions } from 'shared/type.shared';

const button = cva('w-full flex items-center', {
  variants: {
    disabled: {
      true: '!cursor-not-allowed bg-opacity-60 hover:!bg-gray-400 active:!scale-100',
    },
    type: {
      primary: 'bg-gray-primary hover:bg-gray-primary/90 text-white | click:scale fcc duration-150',
      outlined:
        'border border-gray-primary/40 hover:border-gray-primary text-gray-primary hover:bg-gray-primary/5 duration-150 | click:scale fcc',
    } as Record<TTypeOptions, string>,
    rounded: {
      none: 'rounded-none',
      sm: 'rounded-[2px]',
      md: 'rounded-md',
      lg: 'rounded-lg',
    } as Record<TSizeOptions | 'none', string>,
    size: {
      sm: 'py-2 px-2 text-sm',
      md: 'py-3 px-2 text-base',
      lg: 'py-3 px-3 text-lg',
    } as Record<TSizeOptions, string>,
    loading: {
      true: '!cursor-default bg-opacity-90',
    },
  },
});

type TButtonProps = {
  size?: TSizeOptions;
  type?: TTypeOptions;
  onClick?: (...params: unknown[]) => unknown;
  children?: React.ReactNode;
  className?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  htmlType?: 'submit' | 'reset' | 'button' | undefined;
  rounded?: TSizeOptions;
};

function Button(props: TButtonProps) {
  const {
    size = 'md',
    type = 'primary',
    children,
    className = '',
    rightIcon,
    leftIcon,
    onClick,
    loading = false,
    disabled,
    htmlType,
    rounded = 'lg',
    ...rest
  } = props;

  return (
    <>
      <button
        {...rest}
        disabled={disabled || loading}
        type={htmlType}
        onClick={onClick}
        className={clsx(button({ type, size, loading, disabled, rounded }), 'relative', className)}
      >
        {loading ? (
          <>
            <div className='flex items-center justify-center' data-testid='loading-spinner'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 text-white' viewBox='0 0 24 24'>
                <circle cx='4' cy='12' r='3' fill='currentColor' className='spinner_b2T7'></circle>
                <circle cx='12' cy='12' r='3' fill='currentColor' className='spinner_b2T7 spinner_YRVV'></circle>
                <circle cx='20' cy='12' r='3' fill='currentColor' className='spinner_b2T7 spinner_c9oY'></circle>
              </svg>
            </div>

            <span className='absolute inset-0 bg-white/20'></span>
          </>
        ) : (
          <>
            {leftIcon} {children} {rightIcon}
          </>
        )}
      </button>
    </>
  );
}

export default Button;
